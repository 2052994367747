exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-denied-js": () => import("./../../../src/pages/access-denied.js" /* webpackChunkName: "component---src-pages-access-denied-js" */),
  "component---src-pages-build-info-js": () => import("./../../../src/pages/build-info.js" /* webpackChunkName: "component---src-pages-build-info-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-learn-doc-js": () => import("./../../../src/templates/learn-doc.js" /* webpackChunkName: "component---src-templates-learn-doc-js" */)
}

